export default [
    // 商品订单
    {
        path: '/onlineOrder/goodsOrders',
        name: "orderList",
        component: () => import('../views/onlineOrder/goodsOrders'),
    },
    // 支付订单——微信
    {
        path: '/onlineOrder/payOrders',
        name: "payOrders",
        component: () => import('../views/onlineOrder/payOrders'),
    },
    // 支付订单——支付宝
    {
        path: '/onlineOrder/payOrdersAL',
        name: "payOrdersAL",
        component: () => import('../views/onlineOrder/payOrdersAL'),
    },
    // 预支付订单——支付宝
    {
        path: '/onlineOrder/payOrdersALY',
        name: "payOrdersALY",
        component: () => import('../views/onlineOrder/payOrdersALY'),
    },
    // 手动退款——微信
    {
        path: '/onlineOrder/manualRefund',
        name: "manualRefund",
        component: () => import('../views/onlineOrder/manualRefund'),
    },
    // 手动退款——支付宝
    {
        path: '/onlineOrder/manualRefundAl',
        name: "manualRefundAl",
        component: () => import('../views/onlineOrder/manualRefundAl'),
    },
    // 会员列表
    {
        path: '/onlineOrder/members',
        name: "members",
        component: () => import('../views/onlineOrder/members'),
    },
    // 投诉列表-微信
    {
        path: '/onlineOrder/complaintList',
        name: "complaintList",
        component: () => import('../views/onlineOrder/complaintList'),
    },
    // 投诉列表-支付宝
    {
        path: '/onlineOrder/complaintListAL',
        name: "complaintListAL",
        component: () => import('../views/onlineOrder/complaintListAL'),
    },
    // 用户投诉列表-支付宝
    {
        path: '/onlineOrder/userComplaintList',
        name: "userComplaintList",
        component: () => import('../views/onlineOrder/userComplaintList'),
    },
    // 退款申请失败-支付宝
    {
        path: '/onlineOrder/refundFailureList',
        name: "refundFailureList",
        component: () => import('../views/onlineOrder/refundFailureList'),
    },
    // 400电话
    {
        path: '/onlineOrder/phoneCallRecording',
        name: "phoneCallRecording",
        component: () => import('../views/onlineOrder/phoneCallRecording'),
    },
    // 扣单管理列表
    {
        path: '/onlineOrder/adchannelManager',
        name: "phoneCallRecording",
        component: () => import('../views/onlineOrder/adchannelManager'),
    },
    // 扣单管理列表
    {
        path: '/onlineOrder/agreementOrdersAL',
        name: "agreementOrdersAL",
        component: () => import('../views/onlineOrder/agreementOrdersAL'),
    },
]