<template lang="pug">
#app
  div(v-if="this.$route.name === 'signin'")
    router-view
  div(v-else)
    el-container.container
      el-header.header(height="50px")
        el-row
          el-col.logo(:span="isMobile?24:6") 陀螺CMS
          el-col(:span="18" v-if="!isMobile")
            Header
      el-container
        el-aside(width="210px" v-if="!isMobile")
          el-menu.nav-menus(:default-active="currentNavIndex + ''", background-color="#545c64", text-color="#fff", active-text-color="#ffd04b",router, @select="handleNavSelected")
            template(v-for="(item, index) in menus")
              el-submenu(v-if='item.children' :index="item.route")
                template(slot="title") 
                  i(:class="item.icon")
                  span {{item.label}}
                el-menu-item(v-for='(citem) in item.children' :key="citem.route", :index="citem.route")
                  i(:class="citem.icon")
                  span {{citem.label.substring(citem.label.indexOf('/')+2)}}
              el-menu-item(v-else :key="item.route", :index="item.route")
                i(:class="item.icon")
                span {{item.label}}
        .ismobilebox(v-else)
          .menuwrap()
            el-dropdown
              el-button( type="info" icon="el-icon-menu" circle)
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(v-for="item in menus[1].children")
                  el-link(:href="item.route") {{item.label}}
        el-main
          .page-title {{PageTitleName}}
          el-divider
          router-view.main-content
</template>

<script>
import Header from './components/header'

export default {
  components: {
    Header
  },
  data() {
    return {
      menusShowType: true,
      isMobile: ('ontouchstart' in document.documentElement),
      PageTitleName: '',
      currentNavIndex: '',
      menus: [
        {
          icon: 'el-icon-mobile-phone',
          label: '陀螺好物',
          route: '',
          children: [
            {
              icon: 'el-icon-location',
              label: ' 免费领购订单管理',
              route: '/orders'
            },
            {
              icon: 'el-icon-location',
              label: ' 用户管理',
              route: '/users'
            },
            {
              icon: 'el-icon-location',
              label: ' 免费领购商品管理',
              route: '/goods'
            },
            {
              icon: 'el-icon-location',
              label: ' 用户任务记录',
              route: '/records'
            },
            {
              icon: 'el-icon-location',
              label: ' 问答管理',
              route: '/qagames'
            },
            {
              icon: 'el-icon-location',
              label: ' 成语闯关日志',
              route: '/idiomgame.logs'
            },
            {
              icon: 'el-icon-location',
              label: ' 付费商品管理',
              route: '/paidgoods'
            },
            {
              icon: 'el-icon-location',
              label: ' 付费订单管理',
              route: '/paidorders'
            },
            {
              icon: 'el-icon-star-off',
              label: ' 运营商产品管理',
              route: '/CarrierProducts'
            },
            {
              icon: 'el-icon-star-off',
              label: ' 红包管理',
              route: '/redpackets'
            },
            {
              icon: 'el-icon-star-off',
              label: ' 公告管理',
              route: '/notices'
            },
            {
              icon: 'el-icon-star-off',
              label: ' 订阅消息',
              route: '/SubMessageTemplates'
            },
            {
              icon: 'el-icon-star-off',
              label: ' 通用数据统计日志',
              route: '/StatsLogs'
            },
            {
              icon: 'el-icon-star-off',
              label: ' 大家来找茬',
              route: '/SpotsGame'
            },
          ]
        },
        {
          icon: 'el-icon-mobile-phone',
          type: 'phone',
          label: '线上订单',
          route: '/onlineOrder',
          children: [
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 会员列表',
              route: '/onlineOrder/members'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 商品订单',
              route: '/onlineOrder/goodsOrders'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 投诉列表-微信',
              route: '/onlineOrder/complaintList'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 投诉列表-支付宝',
              route: '/onlineOrder/complaintListAL'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 支付订单-微信',
              route: '/onlineOrder/payOrders'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 支付订单-支付宝',
              route: '/onlineOrder/payOrdersAL'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 预支付订单-支付宝',
              route: '/onlineOrder/payOrdersALY'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 用户投诉列表',
              route: '/onlineOrder/userComplaintList'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 退款申请失败-支付宝',
              route: '/onlineOrder/refundFailureList'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 400电话列表',
              route: '/onlineOrder/phoneCallRecording'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 扣单管理列表',
              route: '/onlineOrder/adchannelManager'
            },
            {
              icon: 'el-icon-tickets',
              label: '线上订单 / 签约订单-支付宝',
              route: '/onlineOrder/agreementOrdersAL'
            },
          ]
        },
        {
          icon: 'el-icon-mobile-phone',
          label: '易点流量',
          route: '/flowManagement',
          children: [
            {
              icon: 'el-icon-tickets',
              label: '易点流量 / 广告管理',
              route: '/flowManagement/advertisingManagement'
            },
            // {
            //   icon: 'el-icon-tickets',
            //   label: '易点流量 / 任务中心',
            //   route: '/flowManagement/taskCenter'
            // },
          ]
        },
        {
          icon: 'el-icon-mobile-phone',
          label: '其它配置',
          route: '/otherSettings',
          children: [
            {
              icon: 'el-icon-tickets',
              label: '其它配置 / 充值中心',
              route: '/otherSettings/rechargeCenter'
            },
            {
              icon: 'el-icon-tickets',
              label: '其它配置 / 转账',
              route: '/otherSettings/transfer'
            },
          ]
        }
      ]
    }
  },
  mounted() {
    if (location.pathname !== '/signin') {
      this.getPageTitleName(this.menus, location.pathname)
    }
  },
  methods: {
    gotoPage(page) {
      window.console.log(page)
      window.console.log(location.pathname)
      if (location.pathname !== page) {
        this.$router.push(page)
      }
    },
    getPageTitleName(arr, str) {
      arr.forEach(item => {
        if (item.children) {
          this.getPageTitleName(item.children, str)
        } else {
          if (item.route == str) {
            this.PageTitleName = item.label;
            this.currentNavIndex = str
            return;
          }
        }
      })
    },
    handleNavSelected(indexPath) {
      if (this.currentNavIndex === indexPath) {
        return
      }
      this.getPageTitleName(this.menus, indexPath)
    }
  },
}
</script>

<style lang="less" scope>
body {
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif, '黑体';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.page-title {
  font-size: 16px;
  margin-left: 24px;
}

.header {
  background: #fff;
  box-shadow: 0 3px 3px #c3c3c3;
}

.logo {
  text-align: left;
  color: #409eff;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
}

.links {
  height: 50px;
  line-height: 50px;
  text-align: right;
}

.nav-menus {
  width: 200px;
  min-height: 94vh;
  text-align: left;
}

.list-tools {
  padding: 12px 0;
}

/* common */
.line-space {
  margin: 12px 0;
}

.select-avatar {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.ismobilebox {
  font-size: 4vw;

  .menuwrap {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    z-index: 99;

    .el-button {
      border: none;
      font-size: 1.4rem
    }
  }
}

::v-deep .el-dropdown-menu--small {
  background: #545C64 !important;
  color: #909399;
}
</style>
